import Button from "../Button";
import Title from "../Title";
import Typo from "../Typo";
import SvgError from "../../svg/SvgError";
import {useRouter} from "next/router";
import localized from "../../locale/locales";
import {getLocationContacts} from "../../helpers/locations";

export default function FormStepError({onClose, location}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const contacts = getLocationContacts(location);

    return (
        <div className="text-center pb-8">
            <SvgError width={94} className="mx-auto mb-8"/>
            <Title className="mb-4">{lang.forms.error.title}</Title>
            <Typo className="max-w-lg mx-auto mb-8">
                {lang.forms.error.subtitle}
            </Typo>
            <Typo className="max-w-lg mx-auto mb-4">
                {lang.forms.error.body}
            </Typo>

            {contacts && (
                <div className="flex flex-col items-center gap-2 text-2xl font-medium mb-12">
                    <a href={`mailto:${contacts.email}`} className="draw-line">{contacts.email}</a>
                    <a href={`tel:${contacts.phone.split(' ').join('')}`} className="draw-line">{contacts.phone}</a>
                </div>
            )}

            <Button label={lang.common.close} onClick={onClose}/>
        </div>
    )
}
