import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgError(props) {
    const randomId1 = useMemo(() => randomString('error-1'), []);
    const randomId2 = useMemo(() => randomString('error-2'), []);

    return (
        <svg {...props} viewBox="0 0 94 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6"
                  d="M46.1998 5.3999C40.0998 5.5999 33.8998 7.0999 28.0998 9.9999C7.09984 20.6999 -1.30016 46.2999 9.39984 67.1999C16.4998 81.1999 30.3998 89.5999 44.9998 90.3999"
                  stroke={`url(#${randomId1})`}
                  strokeWidth="9.5" strokeMiterlimit="10"/>
            <path
                d="M52.0001 8.19996C53.7001 4.79996 51.6001 0.699959 47.8001 0.0999588C45.4001 -0.300041 43.0001 0.999959 41.9001 3.09996C40.2001 6.49996 42.3001 10.6 46.1001 11.2C48.5001 11.6 50.9001 10.3 52.0001 8.19996Z"
                fill="currentColor"/>
            <path opacity="0.6"
                  d="M47.6001 90.7002C53.7001 90.5002 59.9001 89.1002 65.8001 86.1002C86.8001 75.5002 95.1001 49.9002 84.5001 28.9002C77.4001 14.9002 63.5001 6.5002 48.9001 5.7002"
                  stroke={`url(#${randomId2})`}
                  strokeWidth="9.5" strokeMiterlimit="10"/>
            <path
                d="M41.9001 88.1999C40.2001 91.5999 42.3001 95.6999 46.1001 96.2999C48.5001 96.6999 50.9001 95.3999 52.0001 93.2999C53.7001 89.8999 51.6001 85.7999 47.8001 85.1999C45.4001 84.8999 43.0001 86.0999 41.9001 88.1999Z"
                fill="currentColor"/>
            <path d="M31.2998 64.2998L47.6998 47.7998" stroke="currentColor" strokeWidth="9.5" strokeMiterlimit="10"/>
            <path d="M31.2998 33.7998L61.7998 64.2998" stroke="currentColor" strokeWidth="9.5" strokeMiterlimit="10"/>
            <path d="M45.5 50L62.1 33.5" stroke="currentColor" strokeWidth="9.5" strokeMiterlimit="10"/>
            <defs>
                <linearGradient id={randomId1} x1="13.8805" y1="11.9123" x2="53.8116" y2="90.6007"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id={randomId2} x1="79.8919" y1="84.2018" x2="39.9609" y2="5.5134"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>

    )
}