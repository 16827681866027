import {inputStyles} from "../../helpers/styles";
import {emailPattern} from "../../helpers/regex";
import Button from "../Button";
import {useForm} from "react-hook-form";
import Title from "../Title";
import Typo from "../Typo";
import Link from "next/link";
import SvgTick from "../../svg/SvgTick";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

const inputs = [
    [
        {
            type: 'text',
            name: 'firstname',
            label: (lang) => lang.common.firstName,
            rules: {
                required: true
            }
        },
        {
            type: 'text',
            name: 'lastname',
            label: (lang) => lang.common.lastName,
            rules: {
                required: true
            }
        },
    ],
    [
        {
            type: 'text',
            name: 'email',
            label: (lang) => lang.common.email,
            rules: {
                required: true,
                pattern: emailPattern
            }
        },
        {
            type: 'text',
            name: 'phone',
            label: (lang) => lang.common.phone,
            rules: {
                required: true,
                minLength: 6
            }
        },
    ]
]

export default function FormStepPersonal({
                                             onSubmit,
                                             buttonLabel,
                                             defaultData,
                                             title,
                                             subtitle,
                                             checkbox = false
                                         }) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const {register, handleSubmit, formState: {errors}} = useForm();

    if (!buttonLabel) buttonLabel = lang.common.nextStep;

    return (
        <form action=""
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-8 pb-6"
        >
            <header>
                <Title className="mb-2">{title}</Title>
                <Typo className="max-w-xl">
                    {subtitle}
                </Typo>
            </header>
            {inputs.map((group, groupKey) => {
                return (
                    <div className="flex flex-col md:flex-row gap-4" key={`FormStepPersonal: ${groupKey}`}>
                        {group.map(({type, name, label, rules}, groupInputKey) => {
                            return (
                                <div className="flex flex-col flex-1" key={`FormStepPersonalInput: ${groupInputKey}`}>
                                    <input {...register(name, rules)}
                                           className={inputStyles(errors[name], 'small')}
                                           placeholder={label(lang)}
                                           defaultValue={defaultData && defaultData[name] !== 'undefined' ? defaultData[name] : ''}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {checkbox && (
                <div className="w-full max-w-xl flex gap-5 items-center">
                    <div>
                        <label
                            className={`checkbox border-2 transition-colors ${errors.agree ? 'border-red-400' : 'border-gray-300'}`}
                            htmlFor="agree">
                            <input type="checkbox" id="agree" {...register('agree', {required: true})}/>
                            <div className="inner">
                                <SvgTick width={13} className="text-white"/>
                            </div>
                        </label>
                    </div>
                    <label htmlFor="agree"
                           className="text-sm text-gray-300 font-medium block leading-relaxed">
                        {lang.common.agreeWith} <Link href="/zzou"
                                                      className="underline mouse-hover:no-underline"
                                                      target="_blank"
                    >{lang.docs.personalDataMarketing}</Link>
                    </label>
                </div>
            )}

            {!checkbox && (
                <Link href="/zzou"
                      target="_blank"
                      className="inline-flex text-sm text-gray-400 underline mouse-hover:no-underline"
                >
                    {lang.docs.personalData} (PDF)
                </Link>
            )}

            <div className="flex mt-6 pr-2 justify-center md:justify-end">
                <Button label={buttonLabel}/>
            </div>
        </form>
    )
}
