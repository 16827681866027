import Button from "../Button";
import Title from "../Title";
import Typo from "../Typo";
import SvgSuccess from "../../svg/SvgSuccess";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

export default function FormStepSuccess({onClose, title, body}) {
    const {locale} = useRouter();
    const lang = localized[locale].common;

    return (
        <div className="text-center pb-8">
            <SvgSuccess width={94} className="mx-auto mb-8"/>
            <Title className="mb-4">{title}</Title>
            <Typo className="max-w-lg mx-auto mb-8">
                {body}
            </Typo>
            <Button label={lang.close} onClick={onClose}/>
        </div>
    )
}
