import {encrypt} from "./encrypt";

const isProduction = process.env.NODE_ENV === 'production';

export const sendToGtm = (data) => {
    if (isProduction) dataLayer.push(data);
}

export const sendTrialToGtm = (email) => {
    const id = encrypt(email) + '_' + Date.now();

    sendToGtm({
        event: 'purchase',
        ecommerce: {
            currencyCode: 'CZK',
            purchase: {
                actionField: {
                    id,
                    revenue: 0,
                    tax: 0,
                    shipping: 0
                },
                products: [
                    {
                        name: 'Trial',
                        id: 'free_trial_training',
                        price: 0,
                        category: 'free_entry',
                        quantity: 1
                    }
                ]
            }
        }
    });

    sendToGtm({
        event: 'lead',
        content_name: 'Trial',
    });

    sendToGtm({
        event: 'complete_registration',
        content_name: 'Trial',
    });
}

export const sendLeadToGtm = (name = 'Contact Form') => {
    sendToGtm({
        event: 'lead',
        content_name: 'Contact Form',
    });
}