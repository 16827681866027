import 'moment/locale/cs';
import {getBookableLocations, getLocationAddress} from "../helpers/locations";


export default function ScheduleTrialLocations({selectedLocation, setSelectedLocation}) {
    return (
        <div className="flex items-center justify-center gap-4 flex-wrap my-9">
            {getBookableLocations().map((location) => {
                const active = location === selectedLocation;
                const locationAddress = getLocationAddress(location);

                return (
                    <button onClick={() => setSelectedLocation(active ? null : location)}
                            className={`border text-sm leading-[1.8] inline-flex py-2.5 px-9 rounded-full transition-colors duration-300 ${active ? 'border-black bg-black text-white' : 'text-black/40 border-black/15 mouse-hover:border-black/40 mouse-hover:text-black/80'}`}
                            key={`ScheduleTrialLocations: ${location}`}
                    >
                        {locationAddress.district}
                    </button>
                )
            })}
        </div>
    )
}
