import SvgArrowLeft from "../svg/SvgArrowLeft";
import SvgArrowRight from "../svg/SvgArrowRight";
import {useRouter} from "next/router";
import localized from "../locale/locales";

const buttonClasses = 'text-center flex-1 py-6 text-black';

export default function ScheduleTrialPanel({dateRange, onPrev, onNext}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    return (
        <div
            className="flex items-stretch justify-between max-w-4xl mx-auto shadow-xl mb-12 rounded-lg overflow-hidden">
            <button
                className="px-8 text-black mouse-hover:text-white mouse-hover:bg-black transition-colors duration-300 text-0"
                onClick={onPrev}
                aria-label={lang.common.previousDays}
            >
                <SvgArrowLeft width={25} id="schedule-trial-panel"/>
                {lang.common.previous}
            </button>

            {dateRange.map((item, key) => {
                return (
                    <div className={`${buttonClasses} ${key === 1 ? 'tiny-border' : ''}`}
                         key={`ScheduleTrialPanel: ${key}`}
                    >
                        <p className="uppercase text-sm mb-1">{item.format('dddd')}</p>
                        <p className="text-gray-400 text-xs font-medium">{item.format('D.M.YYYY')}</p>
                    </div>
                )
            })}

            <button
                className="px-8 text-black mouse-hover:text-white mouse-hover:bg-black transition-colors duration-300 text-0"
                onClick={onNext}
                aria-label={lang.common.nextDays}
            >
                <SvgArrowRight width={25} id="schedule-trial-panel"/>
                {lang.common.next}
            </button>
        </div>
    )
}
