import moment from 'moment';
import 'moment/locale/cs';
import SvgError from "../svg/SvgError";
import {useEffect, useState} from "react";
import SvgChevronDown from "../svg/SvgChevronDown";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import SvgLoading from "../svg/SvgLoading";


export default function ScheduleTrialSlots({slots, handleOpenPopup, loading = false}) {
    const {locale} = useRouter();
    const {homepage: {trial: lang}} = localized[locale];

    const [isOpen, setIsOpen] = useState(false);
    const [isTogglable, setIsTogglable] = useState(false);

    useEffect(() => {
        let maxItems = 0;

        slots.forEach((item) => {
            if (item.length > maxItems) {
                maxItems = item.length;
            }
        });

        setIsTogglable(maxItems > 5);

    }, [slots]);

    const handleClickMore = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        setIsOpen(false);
    }, [slots]);

    if (slots.length === 0) return null;

    return (
        <div className="relative">
            {loading && (
                <div className="w-12 absolute top-[2px] left-1/2 -translate-x-1/2 flex">
                    <SvgLoading className="animate-spin duration-1000"/>
                </div>
            )}
            <div className={`max-w-2xl mx-auto relative ${loading ? 'opacity-10 pointer-events-none' : ''}`}>
                <div className={`grid grid-cols-${slots.length} gap-12`}>
                    {slots.map((group, groupKey) => {
                        return (
                            <div key={`ScheduleTrialSlots Group: ${groupKey}`}
                                 className={`flex flex-col gap-4 max-w-xs mx-auto w-full ${isTogglable ? 'accordion' : ''} ${isOpen ? 'accordion-open' : ''}`}
                            >
                                {group.length === 0 && (
                                    <div className="opacity-50 flex items-center justify-center flex-1 min-h-[56px]">
                                        <SvgError width={40}/>
                                    </div>
                                )}

                                {group.map((item, key) => {
                                    return (
                                        <button
                                            key={`ScheduleTrialSlots: ${groupKey} - ${key}`}
                                            className="bg-gray-100 py-4 px-6 rounded-full mouse-hover:bg-black mouse-hover:text-white transition-colors duration-300 group h-14 max-w-[190px] mx-auto w-full"
                                            onClick={(e) => {
                                                // const fixedTime = moment(item.startDateTime).utc(true).format();
                                                handleOpenPopup(item.startDateTime);
                                                e.target.blur();
                                            }}
                                        >
                                    <span className="group-hover:hidden text-md">
                                        {moment(item.startDateTime).format('HH:mm')}
                                    </span>
                                            <span
                                                className="hidden group-hover:inline-block uppercase text-xs font-semibold">
                                        {lang.cta}
                                    </span>
                                        </button>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                {isTogglable && (
                    <>
                        <div className="line-gradient-gray max-w-xl mx-auto mt-8"/>
                        <div className="flex w-full">
                            <button onClick={handleClickMore}
                                    className="mx-auto flex gap-2 items-center pt-6 px-4 text-xs uppercase text-black"
                            >
                                {isOpen ? lang.showLess : lang.showMore}
                                <SvgChevronDown width={7}
                                                className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                                />
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
